.p-scrollpanel-content {
    font-size: calc(10px + 2vmin) !important;
}

#basics {
    width: 90vw;
    margin: 3vh auto 6vh auto;
    display: flex;
    flex-flow: column;
}

#bigtext {
    margin: 3%;
}

#e404 {
    text-align: center;
    margin-top: 10%;
}

#topline {
    display: flex;
    align-items: center;
}

.wi {
    font-size: 2em;
    margin: 0 10px 0 10px;
}

#basicstats {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.stat {
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0;
    width: 120px;
}

.stat i {
    margin: 10px;
}

.statInner {
    display: flex;
    flex-flow: column nowrap;
}

@media all and (min-width: 500px) {
    #basics {
        flex-flow: row;
    }

    #bigtext {
        width: 60%;
    }

    #basicstats {
        width: 40%;
        max-width: 555px;
    }

    .stat {
        width: 165px;
        margin: 10px;
    }
}