@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

* {
    margin: 0;
    padding: 0;
}

.App {
    background-color: #1f1f1f;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ib {
    display: inline-block;
}

.p-menubar {
    background-color: #282c34 !important;
    border: none !important;
    border-radius: 0 !important;
}

.p-menubar-start {
    display: none;
}

.p-submenu-list {
    z-index: 65536 !important;
}

.p-inputtext {
    border-radius: 0 !important;
}

.p-button {
    border-radius: 0 !important;
}

.p-scrollpanel-bar {
    background: white !important;
}

.p-inputtext {
    width: 150px;
}

#linkback {
    text-align: center;
    width: 100%;
    font-size: calc((10px + 2vmin) * 0.5);
    position: absolute;
    bottom: 0.2em;
}

#linkback a {
    text-decoration: none;
    color: white;
}

@media all and (min-width: 440px) {
    .p-inputtext {
        width: 250px;
    }

    .p-menubar-start {
        margin: 0 1em 0 0.5em;
        display: block;
    }
}